import { Box, SlideFade, Stack } from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import HeadSeemore from "../../components/HeadSeemore";
import DebitCard from "./components/DebitCard";
import OtherServices from "./components/OtherServices";
import SingleTrasaction from "./components/SingleTrasaction";
import Footer from "../../components/footer";
import { useContext, useEffect, useState, useCallback } from "react";
import { getAccount, getAllTransactions, getWallet, verifyPayment } from "./service";
import { AuthContext } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
import { v4 } from "uuid";
import { Transactions } from "./components/Transactions";
import { PROTECTED_PATHS } from "../../app/constants";
import { getUserProfile } from "../settings/service/getUserProfile";
import {
  errorNotifier,
  infoNotifier,
  successNotifier,
} from "../../components/NotificationHandler";

const MyWallet = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);
  const [wallet, setWallet] = useState({});
  const [reload, setReload] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const { ALL_TRANSACTIONS } = PROTECTED_PATHS;
  
  const reference = localStorage.getItem("Due-tnx");

  const verifyTransaction = useCallback(async () => {
    if (reference && userId) {
      setLoading(true);
      try {
        const response = await verifyPayment(reference, userId);
        if (response.success) {
          successNotifier("Payment verified successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          errorNotifier("Payment verification failed!");
        }
      } catch (error) {
        errorNotifier(error.message);
      } finally {
        localStorage.removeItem("Due-tnx");
        setLoading(false);
      }
    }
  }, [reference, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await verifyTransaction();
        await getAllTransactions(userId, setLoading, setAllTransactions);
        await getWallet(userId, setLoading, setWallet);
        await getUserProfile(userId, setLoading, setUserProfile);
      } catch (error) {
        errorNotifier("An error occurred while fetching data.");
      }
    };
    fetchData();
  }, [userId,verifyTransaction]);


  console.log(userProfile, "show", userInfo);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX="2000px">
      <Box pb="10">
        <Box shadow="xs">
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Container>
          <Stack py="10" spacing="6">
            <DebitCard
              setLoading={setLoading}
              userId={userId}
              wallet={userProfile}
              setReload={() => setReload(!reload)}
              data={allTransactions}
            />
            <HeadSeemore head="Transactions" seemore to={ALL_TRANSACTIONS} />
            <Transactions data={allTransactions} />
          </Stack>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default MyWallet;