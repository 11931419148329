import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Img,
  Input,
  Select,
  Stack,
  Text,
  SlideFade,
  useColorModeValue,
  Modal,ModalOverlay,ModalContent,Spinner,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  infoNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { PrimaryButton } from "../../../components/CustomButton";
import { getFamily, requestCard } from "../services";
import { AuthContext } from "../../../context/authContext";
import Footer from "../../../components/footer";
import { formLabelStyles } from "../../issue report/components/issueReportData";
import ConfirmCardPayment from "./ConfirmCardPayment";
import PaystackModal from "./PaystackModal";
import axiosInstance from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../settings/service/getUserProfile";

const RequestId = ({ onClose }) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const [loading, setLoading] = useState(false);
  const [main, setMain] = useState("main");
  const { ID_CARD } = PROTECTED_PATHS;
  const serviceCharge = 3000;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [family, setFamily] = useState([]);
  const [media, setMedia] = useState(null);
  const [selectedFamilyName, setSelectedFamilyName] = useState("");
  const [allowToOpen, setAllowToOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(false);  
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [payBtnDisabled, setBtnDisabled] = useState(true);
  const [formValues, setformValues] = useState({
    name: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (main === "main") {
      setformValues((prev) => ({ ...prev, [name]: value, familyName: "" }));
    } else {
      setformValues((prev) => ({ ...prev, familyName: value }));
    }
  };


  useEffect(() => {
    getUserProfile(userId, setLoading, setUserProfile);

    axiosInstance
      .get(`/card/?phoneNumber=${userProfile?.tenantPhone}`)
      .then((res) => {
        setBtnDisabled(false);
      })
      .catch(() => setBtnDisabled(true));
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    getFamily(userId, setLoading, setFamily);
  }, [userId]);

  useEffect(() => {
    if (main === "main") {
      if (media) {
        setAllowToOpen(true);
        setBtnDisabled(false);
      } else {
        setAllowToOpen(false);
        setBtnDisabled(true);
      }
    } else {
      if (selectedFamilyName && media) {
        setAllowToOpen(true);
        setBtnDisabled(false);
      } else {
        setAllowToOpen(false);
        setBtnDisabled(true);
      }
    }
  }, [
    main,
    family,
    formValues.name,
    formValues.familyName,
    selectedFamilyName,
    media,
  ]);

  const handleImage = (event) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    const acceptedFormats = ["image/png", "image/jpeg", "image/jpg"];

    if (!acceptedFormats.includes(file.type)) {
      infoNotifier("Please upload a valid image file (PNG, JPEG, JPG)");
      return;
    }

    setMedia(file);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let familyDetails = family.find(
      (data) => data?.familyName === selectedFamilyName
    );
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "name",
      main === "main" ? userProfile?.tenantName : selectedFamilyName
    );
    formData.append(
      "street",
      userProfile?.houseId?.streetId?.streetName
      //main === "main" ? familyDetails?.houseId : familyDetails?.houseId
    );
    formData.append("passport", media);
    // formData.append("parent", familyDetails?.parentId);
    // formData.append("cardHolder", familyDetails?._id);
    formData.append("paymentType", "card");

    // formData.append("phone", userProfile?.tenantPhone); // tenantPhone
    formData.append(
      "houseNumber",
      userProfile?.houseId?.estate[0]?.houseNumber
    ); // house number
    formData.append("phoneNumber", userProfile?.houseId?.phoneNumber); // house phone number
    formData.append("email", userProfile?.email); // email
    formData.append("totalAmount", serviceCharge); // email
    formData.append("paymentMethod", "paystack");
    formData.append("channel", "paystack");

    try {
      await requestCard(formData)(setPaymentLoading)(setPaymentModal)(navigate)(
        setPaymentData
      );
      successNotifier("Request submitted successfully!");
    } catch (error) {
      errorNotifier("An error occurred during submission.");
    } finally {
      setIsSubmitting(false); // Hide loading modal
    }
  };

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  const clearMedia = () => {
    setMedia(null);
  };

  const paymentChannel = "routepay"; //paystack or routepay

  return (
    <SlideFade in={true} offsetX="-2000px">
      <>
        {isSubmitting && (
          <Modal isOpen={isSubmitting} onClose={() => {}} isCentered>
            <ModalOverlay />
            <ModalContent
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="transparent"
              shadow="none"
            >
              <Spinner size="xl" color={COLORS.green} />
              <Text mt={4} color="#fff" fontSize="1.4rem" fontWeight="900">
                Submitting... Please wait.
              </Text>
            </ModalContent>
          </Modal>
        )}
      </>
      <Box minH="100vh" pb="15vh">
        <Box shadow="sm">
          <Container>
            <Header goto={ID_CARD} />
          </Container>
        </Box>

        <Container minH="calc(100vh - 100px)">
          <HStack spacing={"20px"}>
            <Text
              color={main === "main" ? "white" : `${cardBg}`}
              fontSize={"20px"}
              bg={main === "main" ? `${COLORS.green}` : "none"}
              p={main === "main" ? "10px 20px" : "0px"}
              rounded={main === "main" ? "3xl" : "0px"}
              onClick={() => {
                setMain("main");
                clearMedia();
              }}
              cursor={"pointer"}
            >
              Main User
            </Text>
            <Text
              color={main === "family" ? "white" : `${cardBg}`}
              fontSize={"20px"}
              bg={main === "family" ? `${COLORS.green}` : "none"}
              p={main === "family" ? "10px 20px" : "0px"}
              rounded={main === "family" ? "3xl" : "0px"}
              onClick={() => {
                setMain("family");
                clearMedia();
              }}
              cursor={"pointer"}
            >
              Family Members
            </Text>
          </HStack>
          <FormControl h="100%" mt="30px">
            <Stack spacing="20px">
              {main === "main" ? (
                <>
                  <Text color={cardBg} fontSize={"20px"}>
                    Upload passport photo and submit to request for a new ID
                    card
                  </Text>
                  <Input
                    {...inputStyle}
                    placeholder="Name"
                    _placeholder={{ opacity: 1, color: "black" }}
                    border="none"
                    borderBottom="1px solid #ccc"
                    borderRadius="0"
                    value={userProfile?.tenantName}
                    readOnly
                  />
                </>
              ) : (
                <>
                  <Text color={cardBg} fontSize={"20px"}>
                    Choose family member to request a new ID card
                  </Text>

                  <Select
                    {...inputStyle}
                    placeholder="Select family"
                    _placeholder={{ opacity: 1, color: "black" }}
                    border="none"
                    color="green"
                    borderBottom="1px solid #ccc"
                    borderRadius="0"
                    onChange={(e) => setSelectedFamilyName(e.target.value)}
                  >
                    {family?.map((member, index) => (
                      <option key={index} value={member?.tenantName}>
                        {member?.tenantName}
                      </option>
                    ))}
                  </Select>
                  <Box>
                    <Text color={cardBg} fontSize={"14px"}>
                      Enter family name as you want it to appear on your card
                    </Text>
                    <Input
                      {...inputStyle}
                      placeholder="Name"
                      name="familyName"
                      _placeholder={{ opacity: 1, color: "black" }}
                      border="none"
                      borderBottom="1px solid #ccc"
                      borderRadius="0"
                      value={formValues.familyName}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              )}
            </Stack>

            <Stack>
              <Box mt={"50px"}>
                <FormLabel {...formLabelStyles}>
                  <HStack cursor="pointer">
                    <Text color={cardBg} fontWeight="500">
                      Upload passport photo
                    </Text>
                    <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
                  </HStack>
                  <Text color={cardBg} fontSize="13px">
                    Ensure that the photo is clear, taken in a bright place and
                    your face is facing front, and fully visible.
                  </Text>
                  <Input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    display="none"
                    id="media"
                    onChange={handleImage}
                  />
                </FormLabel>
              </Box>
              {media && (
                <Img
                  w="70px"
                  h="70px"
                  m="2"
                  borderRadius={"5px"}
                  src={URL.createObjectURL(media)}
                  alt="Passport"
                />
              )}
            </Stack>
            <HStack py="15px" justify="space-between" gap="1.5rem">
              <Stack
                {...inputStyle}
                textAlign="center"
                bg="#c7ebe2"
                px="15px"
                w="150px"
              >
                <Text as="small">Service Charge</Text>
                <Text fontSize="0.9rem" fontWeight="700" m="0 !important">
                  &#8358;{serviceCharge}
                </Text>
              </Stack>
              <ConfirmCardPayment
                allowToOpen={allowToOpen}
                buttonProps={{
                  size: "md",
                  _hover: { background: `${COLORS.green}` },
                  _focus: { background: `${COLORS.green}` },
                  bg: `${COLORS.green}`,
                  p: "10px",
                  borderRadius: "10px",
                  color: "white",
                  w: "150px",
                  textAlign: "center",
                  btnText: "Pay",
                  isDisabled: payBtnDisabled,
                }}
                btnTitle="Pay"
                addCloseModalBtn={true}
              >
                {paymentChannel === "paystack" ? (
                  <ConfirmPaymentComponent
                    userProfile={userProfile}
                    userId={userId}
                    paymentLoading={paymentLoading}
                    family={family}
                    setPaymentData={setPaymentData}
                    setPaymentModal={setPaymentModal}
                    setIsSubmitting={setIsSubmitting}
                    media={media}
                    main={main}
                    formValues={formValues}
                    serviceCharge={serviceCharge}
                    navigate={navigate}
                    setLoading={setLoading}
                  />
                ) : (
                  <ConfirmPaymentComponentRoutePay
                    userProfile={userProfile}
                    userId={userId}
                    paymentLoading={paymentLoading}
                    family={family}
                    setPaymentData={setPaymentData}
                    setPaymentModal={setPaymentModal}
                    setIsSubmitting={setIsSubmitting}
                    media={media}
                    main={main}
                    formValues={formValues}
                    serviceCharge={serviceCharge}
                    navigate={navigate}
                    setLoading={setLoading}
                    selectedFamilyName={selectedFamilyName}
                  />
                )}
              </ConfirmCardPayment>
            </HStack>
            {paymentChannel === "paystack" ? (
              <PaystackModal
                paymentData={paymentData}
                paymentModal={paymentModal}
                setPaymentModal={setPaymentModal}
                onFinish={() => {
                  setIsSubmitting(true);
                  axiosInstance
                    .post("/wallet/verify-payment", {
                      userId,
                      reference: paymentData?.reference,
                    })
                    .then(() => {
                      setIsSubmitting(true);
                      successNotifier("Payment Successful");
                      handleSubmit();
                    })
                    .catch(() => errorNotifier("Payment not Successful"));
                  setIsSubmitting(false);
                }}
                onClose={onClose}
              />
            ) : null}
          </FormControl>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default RequestId;

//Paystack ConfirmPaymentComponent
export const ConfirmPaymentComponent = ({
  paymentLoading,
  userId,
  family,
  userProfile,
  setPaymentData,
  setPaymentModal,
  setIsSubmitting,
  onClose,
}) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <Box color={cardBg}>
      <Text fontWeight="bold" mb="4" fontSize={"1.5em"}>
        Confirm Submission
      </Text>
      <Text mb="4">Are you sure you want to submit this request?</Text>
      <PrimaryButton
        size="md"
        isLoading={paymentLoading}
        btnText="Confirm"
        onClick={() => {
          axiosInstance
            .post("/wallet/initialize-payment", {
              amount: 3000,
              tenantName: userProfile?.tenantName,
              email: userProfile?.email,
              userId,
            })
            .then((res) => {
              const paymentInfo = res?.data?.result?.data;

              setPaymentData(paymentInfo);

              onClose();

              setPaymentModal(true);
            });
        }}
      />
    </Box>
  );
};

//RoutePay ConfirmPaymentComponentRoutePay

export const ConfirmPaymentComponentRoutePay = ({
  paymentLoading,
  userId,
  family,
  userProfile,
  setPaymentData,
  setPaymentModal,
  onClose,
  media,
  main,
  formValues,
  serviceCharge,
  navigate,
  setLoading,
  selectedFamilyName,
}) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handlePayment = async () => {
    try {
      setButtonDisabled(true); // Disable button while processing payment
      const res = await axiosInstance.post("/route-pay/card", {
        amount: 3000,
        email: userProfile?.email,
        userId,
      });

      const paymentInfo = res?.data;
      setPaymentData(paymentInfo);

      // Extract transaction reference
      const tnxRef = paymentInfo?.data?.transactionReference;
      if (!tnxRef) {
        throw new Error("Transaction reference missing.");
      }

      localStorage.setItem("tnx", tnxRef);
      console.log("Transaction reference stored:", tnxRef);

      return paymentInfo; // Return payment information
    } catch (error) {
      console.error("Payment error:", error);
      errorNotifier("Payment failed. Please try again.");
      setButtonDisabled(false); // Re-enable button on error
      throw error; // Rethrow to prevent further steps
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const transactionReference = localStorage.getItem("tnx");

    try {
      // Validate transactionReference
      if (!transactionReference) {
        errorNotifier("Transaction reference is missing. Please retry.");
        return; // Stop execution if the reference is missing
      }

      // Fetch relevant family details if applicable

      // Initialize FormData
      const formData = new FormData();
      formData.append(
        "name",
        main === "main" ? userProfile?.tenantName : selectedFamilyName
      );
      console.log("street",
        userProfile?.houseId?.streetId);
      formData.append(
        "street",
        userProfile?.houseId?.streetId
        //main === "main" ? familyDetails?.houseId : familyDetails?.houseId
      );
      if (!media) {
        throw new Error("Passport photo is required. Please upload a photo.");
      }
      formData.append("passport", media);

      formData.append(
        "houseNumber",
        userProfile?.houseId?.houseNumber
      ); // house number
      formData.append("phoneNumber", userProfile?.tenantPhone); // house phone number
      formData.append("email", userProfile?.email); // email
      formData.append("totalAmount", serviceCharge); // email
      formData.append("paymentType", "card");
      formData.append("paymentMethod", "route-pay");
      formData.append("channel", "route-pay");
      formData.append("paymentReference", transactionReference); // Add the transaction reference

      // Make the request
      const response = await axiosInstance.post("/card/request", formData); // Direct POST request
      return response;
    } catch (error) {
      errorNotifier(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error?.response?.data?.result?.message ||
          error.message ||
          "An error occurred while submitting the request."
      );
      console.log(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error?.response?.data?.result?.message ||
          error.message ||
          "An error occurred while submitting the request."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      const paymentInfo = await handlePayment(); // Step 1: Generate transaction reference
      const response = await handleSubmit(); // Step 2: Submit the request with the reference
console.log(response?.data?.result?.success);
      if (
        response?.data?.result?.success ||
        response?.data?.result?.statusCode === 200
      ) {
        successNotifier("Request submitted successfully!");
        const redirectUrl = paymentInfo?.data?.redirectUrl;

        if (redirectUrl) {
          console.log("Redirecting to:", redirectUrl);
          window.location.href = redirectUrl;
        } else {
          console.warn("Redirect URL is missing.");
        }
      } else {
        errorNotifier(response?.data?.result?.message || "Request failed.");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.error("Error during confirmation:", error);
      errorNotifier(
        error?.response?.data?.result?.message ||
          error.message ||
          "An error occurred during confirmation."
      );
    }
  };

  return (
    <Box color={cardBg}>
      <Text fontWeight="bold" mb="4" fontSize={"1.5em"}>
        Confirm Submission
      </Text>
      <Text mb="4">Are you sure you want to submit this request?</Text>
      <PrimaryButton
        size="md"
        isLoading={paymentLoading || isButtonDisabled} // Spinner state
        onClick={handleConfirm} // Chain the processes
        disabled={isButtonDisabled || paymentLoading} // Disable button
        btnText={
          paymentLoading || isButtonDisabled ? "Processing..." : "Confirm"
        }
      />
    </Box>
  );
};
