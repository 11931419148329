import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";


const BillsBarChart = ({ data = [] }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for the chart.</div>;
  }

  const chartData = {
    labels: data.map((item) => `${item.monthName} ${item.year}`),
    datasets: [
      {
        label: "Total Amount Paid",
        data: data.map((item) => item.totalAmountPaid || 0), // Fallback for missing amounts
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barPercentage: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        labels: data.map((item) => `${item.monthName} ${item.year}`),
      },
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          stepSize: 1000,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};


export default BillsBarChart;
