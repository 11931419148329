import {
  Box,
  Flex,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
  Center,
  VStack,
  Image,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { COLORS } from "../../../constants/colors";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { transition } from "../../..";
import { PROTECTED_PATHS } from "../../../app/constants";
import { getAllTransactions } from "../service";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

export const AllTransactions = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.white, COLORS.green);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const textColor = useColorModeValue(COLORS.primary, COLORS.white);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState();
  const [showButtons, setShowButtons] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  console.log("user Info " + userId);

  const { MY_WALLET } = PROTECTED_PATHS;

  useEffect(() => {
    getAllTransactions(userId, setLoading, setAllTransactions);
  }, [userId]);

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrint = () => {
    setShowButtons(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack pb={"10vh"}>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container>
          {allTransactions && allTransactions.length > 0 ? (
            allTransactions.map((datum) => (
              <Box
                key={datum.id}
                bg={TransBg}
                p="15px"
                rounded={"lg"}
                mt="20px"
                onClick={() =>
                  navigate("/transaction-details", { state: datum })
                }
                _hover={{
                  cursor: "pointer",
                  bg: `${bgColor}`,
                  "& *": {
                    color: TransBg,
                  },
                }}
              >
                <>
                  <Flex justify="space-between" mt="0px">
                    <Text color={cardBg}>
                      {datum?.paymentFor
                        ? datum.paymentFor.charAt(0).toUpperCase() +
                          datum.paymentFor.slice(1)
                        : ""}
                    </Text>
                    <Text color={cardBg}>
                      {datum?.status
                        ? datum.status.charAt(0).toUpperCase() +
                          datum.status.slice(1).toLowerCase()
                        : ""}
                    </Text>
                  </Flex>
                  <Flex justify={"space-between"}>
                    <Text color={COLORS.lightGrey}>
                      {dayjs(datum?.updatedAt).format(" h:mm A MMM D, YYYY")}
                    </Text>
                    <Text color={cardBg}>
                      {" "}
                      &#8358; {datum?.amountSent?.toLocaleString()}
                    </Text>
                  </Flex>
                </>
              </Box>
            ))
          ) : (
            <Center h="calc(100vh - 150px)">
              <VStack>
                <Image src="/Empty.svg" alt="Empty" />
                <Text as="h2" py="5" fontSize="18px" color={cardBg}>
                  You have no transaction yet
                </Text>
              </VStack>
            </Center>
          )}
        </Container>
      </Stack>
    </SlideFade>
  );
};